import axios from "axios";
import qs from "qs"
import CryptoJS from 'crypto-js';

// import {appinfo} from '../commondata'

let appinfo = {
    platform:"web",
    protype:"SuperCN",
    channel:"officialweb"
}
let aesKey = CryptoJS.MD5(Math.random()).toString();
console.log("AESKEY",aesKey)
/**
 * aes 加密
 * 
 */
let aesEncrypt=(word)=> {
    let key = CryptoJS.SHA256(CryptoJS.MD5(aesKey).toString());
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let iv = CryptoJS.enc.Utf8.parse("");
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { iv:iv,mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
}
/**
 * 
 * aes 解密
 */
let aesDecrypt=(word) =>{
    var key = CryptoJS.SHA256(CryptoJS.MD5(aesKey).toString());
    let iv = CryptoJS.enc.Utf8.parse("");
    var decrypt = CryptoJS.AES.decrypt(word, key, { iv:iv,mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    console.log("decrypt",decrypt)
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};

/** 
 * 处理请求数据
 * 
*/
let dealData= (data)=>{
    data =  Object.assign(data, appinfo);
    let ordered = {};
    Object.keys(data).sort().forEach(function(key) {
        ordered[key] = data[key];
    });
    return qs.stringify({
        "encrypt":aesEncrypt(JSON.stringify(ordered)),
        "once":aesKey
    });
}

let dealQueryData = (data) => {
    data =  Object.assign(data, appinfo);
    let a = ""
    Object.keys(data).sort().forEach(function(key) {
        a+=key+'='+data[key]+"&"
    });
    return a;
}

const request = axios.create({
    baseURL:'https://api.supercnn.com', //基础url
    timeout:10000,//请求超时
  });
  
  /* request.interceptors.request.use(
    config=>{
      config.headers.Authorization = "Bearer "+sessionStorage.getItem("token");
      return config
    }
  ); */
  
request.interceptors.response.use(
    res=>{
        if(res.status === 200 && res.data.code !== 0) {
            let result= {};
            if(res.data.data != '') {
                result.data = JSON.parse(aesDecrypt(res.data.data));
            }
            result.code = res.data.code;
            result.message = res.data.message
            return result;
        }else if( res.status === 200 ){
            let result = {};
            result.code = res.data.code;
            result.message = res.data.message;
            result.data = "";
            return result;
        }
        return res.data;
    },
    error=>{
        throw new Error(error.toString())
        // Promise.reject(error);
    }
);
request.postURL=function(url,data){
    return new Promise((resolve,reject)=>{
        request({
        url:url,
        data:dealData(data),
        method:"post",
        headers:{"Content-Type":"application/x-www-form-urlencoded"}
        })
        .then(res=>resolve(res))
        .catch(err=>reject(err))
    })
};

request.getURL = (url) => {
    return new Promise((resolve,reject)=>{
        request({
        url:url,
        data:"",
        method:"get",
        headers:{"Content-Type":"application/x-www-form-urlencoded"}
        })
        .then(res=>resolve(res))
        .catch(err=>reject(err))
    })
}
export const entryData = dealData;
export const queryData = dealQueryData;
export default request;