<template>
    <div class="header">
        <div class="logo">
            <a href="/"><img src="~@/assets/images/cdn_web_logo.png" alt=""></a>
        </div>
        <div class="nav">
            <div class="nav-item">
                <a href="/" :class="checkActive('/')">主页</a>
            </div>
            <!-- <div class="nav-item">
                <a href="/product_cdn.html" :class="checkActive('/product_cdn.html')">VIP购买会员</a>
            </div> -->
            <div class="nav-item">
                <a href="/tos.html" :class="checkActive('/tos.html')">用户协议</a>
            </div>
            <div class="nav-item">
                <a href="/privacy.html" :class="checkActive('/privacy.html')">隐私条款</a>
            </div>
            <!-- <div class="nav-item userinfo" v-if="userlogined==true" @click="goto('/usercenter_cdn.html')">
                <div :class="'user ' + checkActive('/usercenter_cdn.html')">
                    <i></i>
                    <span>UID:{{userid}}</span>
                </div>
                
                <span>|</span>
                <a href="javascript:void(0)" @click="logoout">退出</a>
            </div> -->
            <!-- <div class="nav-item" v-if="userlogined==false" @click="showlogin">
                <div class="login-btn">
                    登录
                </div>
            </div> -->
        </div>
        <div class="loginContain" v-show="showLogin==true">
            <i class="close" @click="hidelogin"></i>
            <div class="loginContent">
                <div>
                    <div class="login-logo">
                        <img src="@/assets/images/cdn_login_logo.png" alt="">
                    </div>
                    <div class="login-desc">
                        <div id="webqrcode"></div>
                        <div>
                            <img src="@/assets/images/login_uid.png" alt="">
                        </div>
                    </div>
                    <div class="login-more">扫描二维码下载移动客户端</div>
                    <div class="login-more">个人中心获取登录<font color="#F89538">帐号</font>与<font color="#F89538">密码</font></div>
                </div>
                <div></div>
                <div>
                    <div class="login-title">
                        登录
                    </div>
                    <div class="login-form">
                        <div>
                            <i></i>
                            <input type="text" name="userid" placeholder="请输入账号" v-model="username" @keyup.enter='dologin'/>
                        </div>
                        <div>
                            <i></i>
                            <input type="password" name="password" placeholder="请输入密码" v-model="password" @keyup.enter='dologin'/>
                        </div>
                        <div>
                            <span class="error">{{errormsg}}</span>
                        </div>
                        <div>
                            <button @click="dologin">登录</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div id="mask" v-show="mask==true"></div>
    </div>
</template>

<script>
import utils from '@/utils';
import QRCode from 'qrcodejs2'
    export default {
        name:"HeaderCom",
        computed:{
        },
        data(){
            return {
                userlogined:false,
                userid:"",
                showLogin:false,
                mask:false,
                username:"",
                password:"",
                errormsg:""
            }
        },
        methods:{
            goto(url) {
                location.href = url;
            },
            //检查是否在当前路由
            checkActive(path1) {
                let path = location.pathname;
                if(path == path1) {
                    return 'active';
                }else{
                    if(path.length > 1 && path1.substring(0,path.length) == path) {
                        return 'active';
                    }
                }
                return '';
            },
            // 退出登录
            logoout(){
                utils.removeSession(utils.USER_KEY);
                this.username = '';
                this.password = '';
                this.userid = '';
                this.checkLogin();
            },
            getUserid() {
                return this.userid
            },
            //
            checkLogin() {
                let userString = utils.getSession(utils.USER_KEY)
                console.log(userString)
                if(userString!= undefined) {
                    let userinfo = JSON.parse(userString)
                    if(userinfo && userinfo.u ){
                        this.userid = userinfo.u;
                        this.userlogined = true;
                        return this.userid;
                    }
                }
                this.userid = "";
                this.userlogined = false;
                return false;
            },
            // 显示登录窗口
            showlogin(){
                this.showLogin = true;
                this.mask = true
            },
            hidelogin(){
                this.showLogin = false;
                this.mask = false;
            },
            webqrcode(){
                document.querySelector('#webqrcode').innerHTML = '';
                new QRCode('webqrcode',{
                    width:122,
                    height:122,
                    text:"https://www.supercnn.com/"
                })
            },
            dologin() {
                let formData = new FormData() 
                formData.append("userid",this.username)
                formData.append("password",this.password)
                let _this = this;
                utils.loginApi({userid:this.username,password:this.password}).then(res=>{
                    console.log(res)
                    if(res.code === 1) {
                        this.hidelogin();
                        utils.addSession(utils.USER_KEY,JSON.stringify({u:this.username,v:res.data.level,e:res.data.expiration,s:res.data.srvname}))
                        history.go(0)
                    }else{
                        _this.errormsg=res.message
                    }
                })
            }
        },
        mounted(){
            this.checkLogin();
            this.webqrcode();
        }
    }
</script>

<style scoped>
    .header{width:100%;height:82px;display: flex;flex-direction: row;justify-content: space-between;}
    .header .logo{width:190px;height:42px;padding-top:20px;}
    .header .logo:hover{cursor: pointer;}
    .logo img{width:100%;}
    .nav{display: flex;flex-direction: row;height:42px;margin-top:20px;}
    .nav-item{margin-left:78px;line-height: 42px;}
    .nav-item a{position: relative;font-size: 16px;font-weight: bold;color: #7F7F7F;}
    .nav-item a:hover,.nav-item a.active{color:#00D177}
    .nav-item a:before{content: "";position: absolute;left: 50%;bottom: -8px;width: 0;height: 2px;background: #00D177;transition: all .3s;}
    .nav-item a:hover:before{width: 100%;left: 0;right: 0;color:#00D177}
    .nav-item a.active:before{width: 100%;left: 0;right: 0;color:#00D177}
    .login-btn{width: 72px;height: 30px;font-weight: bold;color: #7F7F7F;border: 1px solid #949494;border-radius: 8px;text-align: center;line-height:32px;margin-top:5px;}
    .login-btn:hover{background: #00D177;border: 1px solid #00D177;color:#FFFFFF;cursor: pointer;}

    .user,.userinfo{display: flex;flex-direction: row;}
    
    .user i{display: inline-block;width:28px;height: 28px;margin-top:6px;margin-right:10px;background: url('@/assets/images/my_uid.png') no-repeat;}
    .userinfo span{margin-right:16px;font-size: 16px;font-weight: 400;color: #7F7F7F;}
    .user:hover i,.user.active i{background-position: -28px 0;}
    .user:hover span:nth-child(2),.user.active span:nth-child(2){color:#F78444}
    .user:hover{cursor: pointer;}

.loginContain{width: 800px;
height: 430px;
background: #FFFFFF;
position: fixed;
top:50vh;
margin-top:-215px;
left:50%;
margin-left:-400px;
z-index:999;}
#mask{width:100%;height:100vh;background: rgba(0,0,0,0.8);position: fixed;left:0;top:0;z-index:998}

.close{display: block;width:32px;height:32px;background: url('@/assets/images/login_close.png') no-repeat;position: absolute;right:0;top:-32px;cursor: pointer;}

.loginContent{display: flex;flex-direction: row;justify-content: center;}
.loginContent>div:nth-child(2){width:1px;height: 340px;background: #E8EBF7;margin-top:45px;}
.loginContent>div:nth-child(1){padding:33px 34px;width:332px;}
.loginContent>div:nth-child(3){padding:36px 45px;width:310px;}

.login-logo{height: 32px;margin-bottom: 15px;}
.login-desc{display: flex;flex-direction:row;justify-content: space-between;margin-bottom:30px;}
.login-desc>div:first-child{width:122px;height: 122px;padding:18px;border: 1px solid #CEDAE5;margin-top:27px;}

.login-more{font-size: 16px;font-weight: 400;color: #333333;text-align: center;margin-bottom:3px;;}


.login-title{font-size: 18px;font-weight: bold;color: #333333;position: relative;margin-bottom:55px;;}
.login-title::after{content:"";width: 38px;height: 3px;background: #F89538;position: absolute;bottom:-3px;left:0}

.login-form input{display: block;width:270px;height:48px;line-height: 48px;;outline: none;border:none;border-bottom: 1px solid #EAEFF1;
font-size: 16px;
font-weight: 400;

color: #4B6289;padding-left:40px}

.login-form>div{position: relative;margin-bottom:22px;}
.login-form>div i{position:absolute;left:0;top:13px; display:block;width: 22px;height: 22px;background: url("@/assets/images/login_account.png") no-repeat;}
.login-form>div:nth-child(2) i{background-position: -22px 0;}


.login-form button{
    border:none;
    outline: none;
    width: 308px;
height: 48px;
background: #00D177;
border-radius: 4px;
line-height: 48px;
font-size: 16px;
font-weight: 400;
color: #FFFFFF;
cursor: pointer;
}

.login-form>div:nth-child(3){height:45px;line-height: 45px;font-size: 12px;
font-weight: 400;
color: #00D177;margin-bottom: 0;}
.login-form>div:nth-child(2){margin-bottom: 0;}

</style>