import request from "./request"
import {queryData} from "./request"

const utils = {}
utils.USER_KEY = "userinfo"
utils.addSession = (key,value) =>{
    window.sessionStorage.setItem(key,value)
}

utils.getSession = (key)=>{
    return window.sessionStorage.getItem(key)
}

utils.removeSession = (key)=>{
    window.sessionStorage.removeItem(key)
}
utils.loginApi = (data)=>request.postURL('/u/weblogin',data);//登录API
utils.productApi = ()=>request.postURL('/pro/webser.json',{});//登录API
utils.alipayApi = (data)=>request.postURL('/trade/aliffweb',data); // 支付宝扫码支付
utils.wxpayApi = (data)=>request.postURL('/trade/wxweb',data); // 微信扫码支付
utils.orderlistApi = (data)=>request.postURL('/trade/listweb',data); // 用户支付订单列表

utils.queryData = (data)=>{return queryData(data)}
export default utils