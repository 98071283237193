<template>
    <div class="footer">
        <div class="section">
            <div class="logo"><img src="@/assets/images/cdn_web_logo.png" alt=""></div>
            <div class="container">
                <div>
                    <div class="content">
                        <a href="/">Copyright © 2011-2024 北京云网互连科技有限公司.   </a>
                        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022019872号-1</a>
                    </div>
                </div>
                <div class="px1"></div>
                <div>
                    <div class="title">产品信息</div>
                    <div class="content">
                        <a href="/#node">节点覆盖</a>
                        <a href="/#core">核心功能</a>
                        <a href="/#secene">应用场景</a>
                    </div>
                </div>
                <div class="px1"></div>
                <div>
                    <div class="title">服务与协议</div>
                    <div class="content">
                        <a href="/tos.html">用户协议</a>
                        <a href="/privacy.html">隐私条款</a>
                    </div>
                </div>
                <div class="px1"></div>
                <div>
                    <div class="title">产品服务</div>
                    <div class="content">
                        <!-- <a href="/product_cdn.html">获取VIP</a> -->
                        <a href="mailto:support@supercnn.com">邮箱：support@supercnn.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"FooterCom"
    }
</script>

<style scoped>
    .footer{width:100%;background: #EFF3F7;padding:40px 0 70px;}
    .section{width:1320px;margin:0 auto;}
    .logo{height:40px;margin-bottom:40px;}
    .container{display: flex;flex-direction:row;justify-content: space-between;}
    .container .title{font-size: 16px;font-weight: bold;color: #41567C;margin-bottom:21px;}
    .container .content a{display: block;font-size: 14px;font-weight: 400;color: #525263;margin-bottom:12px;}
    .container .content a:hover{color: #00D177;}
    .px1{width:1px;height: 190px;background: #D0D9E1;}
</style>